import React from 'react';
import "../../helpers/locale/locale_BR";
import { retornarDiaSemanaAbreviada, formatarColunaDoisValores2, toDate, zeroEsquerda, formatarColunaClassCss, valorEmMoeda, valorEmInteiro, showMedium, hideMedium, toMoney, formatarColunaPercentualValor, formatarFooterTitulo, retornarPercentual, formatarFooterColunaPercentualValor } from "../../helpers/utils";
import {
  bizGridResponsive
} from "../../helpers/biz-padrao-visual";
import { API } from "../../config/index";
import { GridRowModel } from '@bryntum/grid/grid.umd.js';
import { retornarIdCliente } from '../../cliente/variaveis';


class GridLinha extends GridRowModel {
  updateLinha(linha) {
    this.set({
      faturamento_01: 0,
    });
    this.set({
      faturamento_01: linha.faturamento_01
    })
  }
}

const retornarGridConfigReal = (dados, grid, token) => {
  const larguraColunaComputador = 105;
  const configResponsiveLevels = { '*': { width: larguraColunaComputador }, small: { width: 100 } }

  let colunas = [
    {
      text: dados.header.data_venda,
      field: "data_venda",
      sum: true,
      summaryRenderer: () => {
        return "Total";
      },
      locked: true,
      minWidth: 200,
      filterable: false,
      renderer: ({ value, cellElement, record }) => {
        return `${retornarDiaSemanaAbreviada(value)} ${toDate(value)}`;
      },
      responsiveLevels: hideMedium,
      align: "center"
    },
    {
      text: dados.header.data_venda,
      field: "data_venda",
      sum: true,
      summaryRenderer: () => {
        return "Total";
      },
      renderer: ({ value, cellElement, record }) => {
        return `${retornarDiaSemanaAbreviada(value)} ${toDate(value)}`;
      },
      minWidth: 120,
      locked: true,
      responsiveLevels: showMedium,
      filterable: false,
      align: "center"
    }
  ];
  for (var i = 1; i <= 5; i++) {
    let agrupador = dados.header[`agrupador_${zeroEsquerda(i, 2)}_colunas`];
    if (agrupador) {
      let listaColunas = agrupador.split(",");
      let children = [];
      if (listaColunas.length > 1) {
        listaColunas.forEach(coluna => {
          children.push(
            {
              align: "right",
              text: dados.header[`descricao_${zeroEsquerda(coluna, 2)}`],
              field: `faturamento_${zeroEsquerda(coluna, 2)}`,
              type: "number",
              sum: true,
              htmlEncode: false,
              renderer: ({ value, cellElement, record }) => {
                let coluna = cellElement.dataset.column.split("_")[1];
                cellElement.classList.add(dados.header[`classe_${coluna}`]);
                return formatarColunaDoisValores2(toMoney(value, 2), toMoney(record[`cupons_${coluna}`]),);
              },
              responsiveLevels: configResponsiveLevels,
              filterable: false,
              hidden: !dados.header[`coluna_${zeroEsquerda(coluna, 2)}_visivel`],
              cls: dados.header[`classe_${zeroEsquerda(coluna, 2)}`]
            }
          )
        });
        colunas.push({
          text: dados.header[`agrupador_${zeroEsquerda(i, 2)}_descricao`],
          align: "center",
          children
        });
      } else {
        colunas.push({
          align: "right",
          text: dados.header[`descricao_${zeroEsquerda(agrupador, 2)}`],
          field: `faturamento_${zeroEsquerda(agrupador, 2)}`,
          type: "number",
          htmlEncode: false,
          sum: true,
          renderer: ({ value, cellElement, record }) => {
            let coluna = cellElement.dataset.column.split("_")[1];
            cellElement.classList.add(dados.header[`classe_${coluna}`]);
            return formatarColunaDoisValores2(toMoney(value, 2), toMoney(record[`cupons_${coluna}`]),)
          },
          responsiveLevels: configResponsiveLevels,
          filterable: false,
          hidden: !dados.header[`coluna_${zeroEsquerda(agrupador, 2)}_visivel`],
          cls: dados.header[`classe_${zeroEsquerda(agrupador, 2)}`]
        });
      }
    }
  }
  return {
    responsiveLevels: bizGridResponsive,
    store: {
      tree: true,
      modelClass: GridLinha,
      readUrl: `${API}/api/varejo/ws_vendas_unidades`,
      fetchOptions: {
        method: 'POST',
        redirect: 'follow'
      },
      responseDataProperty: "ResultadoObjeto.data",
      successDataProperty: 'responseMeta.data',
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json"
      },
      listeners: {
        afterRequest(event) {
          if (event != "" && event.json && event.json.ResultadoObjeto && event.json.ResultadoObjeto.data) {
            dados.footer = event.json.ResultadoObjeto.footer;
            if (this.getAt(0)) {
              this.getAt(0).updateLinha(event.json.ResultadoObjeto.data[0]);
            }
          }
        },
        exception({ response }) {
          console.log(response || "");
        }
      }
    },
    columns: colunas
  };

}

const retornarGridConfigBenassi = (dados, grid, token) => {
  const larguraColunaComputador = 105;
  const configResponsiveLevels = { '*': { width: larguraColunaComputador }, small: { width: 100 } }

  let colunas = [
    {
      text: dados.header.data_venda,
      field: "data_venda",
      sum: true,
      summaryRenderer: () => {
        return "Total";
      },
      flex: 1,
      filterable: false,
      renderer: ({ value, cellElement, record }) => {
        return `${retornarDiaSemanaAbreviada(value)} ${toDate(value)}`;
      },
      responsiveLevels: hideMedium,
      align: "center"
    },
    {
      text: dados.header.data_venda,
      field: "data_venda",
      sum: true,
      summaryRenderer: () => {
        return "Total";
      },
      renderer: ({ value, cellElement, record }) => {
        return `${retornarDiaSemanaAbreviada(value)} ${toDate(value)}`;
      },
      minWidth: 120,
      locked: true,
      responsiveLevels: showMedium,
      filterable: false,
      align: "center"
    }
  ];
  for (var i = 1; i <= 5; i++) {
    let agrupador = dados.header[`agrupador_${zeroEsquerda(i, 2)}_colunas`];
    if (agrupador) {
      let listaColunas = agrupador.split(",");
      let children = [];
      if (listaColunas.length > 1) {
        listaColunas.forEach(coluna => {
          //children.push(
          colunas.push(
            {
              align: "right",
              text: dados.header[`descricao_${zeroEsquerda(coluna, 2)}`],
              field: `faturamento_${zeroEsquerda(coluna, 2)}`,
              type: "number",
              sum: true,
              htmlEncode: false,
              renderer: ({ value, cellElement, record }) => {
                let coluna = cellElement.dataset.column.split("_")[1];
                cellElement.classList.add(dados.header[`classe_${coluna}`]);
                return formatarColunaDoisValores2(`R$ ${toMoney(value, 2)}`, `${toMoney(record[`quantidade_${coluna}`], 1)} KG`);
              },
              //responsiveLevels: configResponsiveLevels,
              flex: 1,
              filterable: false,
              hidden: true,
              cls: dados.header[`classe_${zeroEsquerda(coluna, 2)}`]
            }
          );

          colunas.push(
            {
              align: "right",
              text: dados.header[`descricao_${zeroEsquerda(coluna, 2)}`],
              field: `faturamento_liquido_${zeroEsquerda(coluna, 2)}`,
              type: "number",
              sum: true,
              htmlEncode: false,
              renderer: ({ value, cellElement, record }) => {
                let coluna = cellElement.dataset.column.split("_")[2];
                cellElement.classList.add(dados.header[`classe_${coluna}`]);
                return formatarColunaDoisValores2(`R$ ${toMoney(value, 2)}`, `${toMoney(record[`quantidade_${coluna}`], 1)} KG`);
              },
              //responsiveLevels: configResponsiveLevels,
              flex: 1,
              filterable: false,
              hidden: true,
              cls: dados.header[`classe_${zeroEsquerda(coluna, 2)}`]
            }
          )
        });
        // colunas.push({
        //   text: dados.header[`agrupador_${zeroEsquerda(i, 2)}_descricao`],
        //   align: "center",
        //   children
        // });
      } else {
        colunas.push({
          align: "right",
          text: dados.header[`descricao_${zeroEsquerda(agrupador, 2)}`],
          field: `faturamento_${zeroEsquerda(agrupador, 2)}`,
          type: "number",
          htmlEncode: false,
          sum: true,
          renderer: ({ value, cellElement, record }) => {
            let coluna = cellElement.dataset.column.split("_")[1];
            cellElement.classList.add(dados.header[`classe_${coluna}`]);
            return formatarColunaDoisValores2(`R$ ${toMoney(value, 2)}`, `${toMoney(record[`quantidade_${coluna}`], 1)} KG`)
          },
          flex: 1,
          filterable: false,
          hidden: true,
          cls: dados.header[`classe_${zeroEsquerda(agrupador, 2)}`]
        });

        colunas.push({
          align: "right",
          text: dados.header[`descricao_${zeroEsquerda(agrupador, 2)}`],
          field: `faturamento_liquido_${zeroEsquerda(agrupador, 2)}`,
          type: "number",
          htmlEncode: false,
          sum: true,
          renderer: ({ value, cellElement, record }) => {
            let coluna = cellElement.dataset.column.split("_")[2];
            cellElement.classList.add(dados.header[`classe_${coluna}`]);
            return formatarColunaDoisValores2(`R$ ${toMoney(value, 2)}`, `${toMoney(record[`quantidade_${coluna}`], 1)} KG`)
          },
          flex: 1,
          filterable: false,
          hidden: true,
          cls: dados.header[`classe_${zeroEsquerda(agrupador, 2)}`]
        });
      }
    }
  }
  return {
    responsiveLevels: bizGridResponsive,
    store: {
      tree: true,
      modelClass: GridLinha,
      readUrl: `${API}/api/varejo/ws_vendas_unidades`,
      fetchOptions: {
        method: 'POST',
        redirect: 'follow'
      },
      responseDataProperty: "ResultadoObjeto.data",
      successDataProperty: 'responseMeta.data',
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json"
      },
      listeners: {
        afterRequest(event) {
          if (event != "" && event.json && event.json.ResultadoObjeto && event.json.ResultadoObjeto.data) {
            dados.footer = event.json.ResultadoObjeto.footer;
            if (this.getAt(0)) {
              this.getAt(0).updateLinha(event.json.ResultadoObjeto.data[0]);
            }
          }
        },
        exception({ response }) {
          console.log(response || "");
        }
      }
    },
    columns: colunas
  };

}

const gridConfig = (dados, grid, token) => {

  const idCliente = retornarIdCliente();
  if (idCliente == "real")
    return retornarGridConfigReal(dados, grid, token)

  if (idCliente == "benassi")
    return retornarGridConfigBenassi(dados, grid, token)
};

export { gridConfig };
