import React from "react";
import { Redirect } from "react-router-dom";
import RaioXLoja from "../pages/raioXLoja";
import AmchartPagina from "../pages/amchart";
import Diario from "../pages/diario";
import GraficoHoraLojas from "../pages/graficoHoraLojas";
import GraficoUnidades from "../pages/graficoUnidades";
import MeuPerfil from "../pages/meuPerfil";
import ReaLina from "../pages/reaLina";
import IndicadoresVendaEstagios from "../pages/btflex/indicadoresVendaEstagios";
import ListagemPedidosEstagios from "../pages/btflex/listagemPedidosEstagios";
import Vendedores from "../pages/btflex/vendedores";
import DesempenhoVendedor from "../pages/btflex/desempenhoVendedor";
import RankingProdutos from "../pages/rankingProdutos";
import TabuleiroNegocios from "../pages/tabuleiroNegocios";
import TopSujeitos from "../pages/topSujeitos";
import TopFamiliasOnDemand from "../pages/topFamiliasOnDemand";
import TopProdutos from "../pages/topProdutos";
import CoposContribuicao from "../pages/coposContrubuicao";
import GraficoCachoeira from "../pages/graficoCachoeira";
import GraficoArvore from "../pages/graficoArvore";
import GraficoEvolucaoDespesas from "../pages/graficoEvolucaoDespesas";
import Xpto from "../pages/xpto";
import PainelReposicaoLojas from "../pages/painelReposicaoLojas";
import PainelReposicaoProdutos from "../pages/painelReposicaoProdutos";
import cinemaResultados from "../pages/cinemaResultados";
import SaldosContas from "../pages/saldosContas";
import GridVendasUnidadesDiarias from "../pages/gridVendasUnidadesDiarias";
import FluxoCaixa from "../pages/fluxoCaixa";
import Blank from "../pages/home";
import MapaNegociosUnidades from "../pages/mapaNegociosUnidades";
import GridMargemFilial from "../pages/benassi/gridMargemFilial";
import GridMargemProduto from "../pages/benassi/gridMargemProduto";
import GridMargemNegocio from "../pages/benassi/gridMargemNegocio";
import PreFiltroProdutos from "../pages/benassi/preFiltroProdutos";
import GridAgentesHolograma from "../pages/capitalRelacional/gridAgentesHolograma";
import PreFiltroUnidades from "../pages/benassi/preFiltroUnidades";
import GridRelacaoOnDemanda from "../pages/capitalRelacional/gridRelacaoOnDemanda";
import PedidosDeVendas from "../pages/btflex/pedidosDeVendas";
import CreditoDeImposto from "../pages/btflex/creditoDeImposto";
import SaldoDePedidosDeCompras from "../pages/btflex/saldoDePedidosDeCompras";
import GraficoReviravoltaAia from "../pages/capitalRelacional/graficoReviravoltaAia";
import GridPainelCamadasNegocio from "../pages/capitalRelacional/gridPainelCamadasNegocio";
import GraficoPainelRelacional from "../pages/capitalRelacional/graficoPainelRelacional";
import BoaConversa from "../pages/capitalRelacional/boaConversa";
import GraficoAgora from "../pages/capitalRelacional/graficoAgora";
import GraficoVisaoSuplicas from "../pages/capitalRelacional/graficoVisaoSuplicas";
import GraficoFormulaLucro from "../pages/capitalRelacional/graficoFormulaLucro";
import GraficoEvolucaoRelacinal from "../pages/capitalRelacional/graficoEvolucaoRealacional";
import RaioXLojaBTFlex from "../pages/btflex/raioXLoja";
import ReaLinaBTFlex from "../pages/btflex/reaLina";
import GraficoUnidadesBTFlex from "../pages/btflex/graficoUnidades";
import DiarioBTFlex from "../pages/btflex/diario";
import TabuleiroNegociosBTFlex from "../pages/btflex/tabuleiroNegocios";
import CinemaResultadosBTFlex from "../pages/btflex/cinemaResultados";
import ProjecaoResultadosBTFlex from "../pages/btflex/projecaoResultados";
import GraficoCachoeiraBTFlex from "../pages/btflex/graficoCachoeira";
import CoposContribuicaoBTFlex from "../pages/btflex/coposContrubuicao";
import XptoBTFlex from "../pages/btflex/xpto";
import SaldosContasBTFlex from "../pages/btflex/saldosContas";
import FluxoCaixaBTFlex from "../pages/btflex/fluxoCaixa";
import GridVendasUnidadesDiariasBTFlex from "../pages/btflex/gridVendasUnidadesDiarias";
import TopFamiliasOnDemandBTFlex from "../pages/btflex/topFamiliasOnDemand";
import GraficoEvolucaoDespesasBTFlex from "../pages/btflex/graficoEvolucaoDespesas";
import CustoProducaoBTFlex from "../pages/btflex/custoProducao";
import { urlApiPainelReposicaoProdutos } from "../helpers/api";

const rotas = {
  btflex: [
    { path: "/dashboard", component: () => <Blank /> },
    {
      path: "/indicadores-de-vendas/estagios",
      component: IndicadoresVendaEstagios,
      layout: "nenhum",
    },
    {
      path: "/ritmo-do-negocio",
      component: DiarioBTFlex,
      layout: "nenhum"
    },
    {
      path: "/indicadores-de-vendas/listagemPedidosEstagios/:mes/:ano/:status/:estagio",
      component: ListagemPedidosEstagios,
      layout: "nenhum",
    },
    {
      path: "/indicadores-de-vendas/desempenho-do-vendedor/",
      component: DesempenhoVendedor,
      layout: "nenhum",
    },
    {
      path: "/batida-do-tambor",
      component: GraficoUnidadesBTFlex,
      layout: "nenhum"
    },
    {
      path: "/bizoiada",
      component: RaioXLojaBTFlex,
      layout: "nenhum"
    },
    {
      path: "/indicadores-de-vendas/vendedores",
      component: Vendedores,
      layout: "nenhum",
    },
    {
      path: "/tabuleiro-de-negocios",
      component: TabuleiroNegociosBTFlex,
      layout: "nenhum",
    },
    {
      path: "/credito-de-imposto",
      component: CreditoDeImposto,
      layout: "nenhum",
    },
    {
      path: "/pedidos-de-vendas",
      component: PedidosDeVendas,
      layout: "nenhum",
    },
    {
      path: "/saldo-pedidos-de-compras",
      component: SaldoDePedidosDeCompras,
      layout: "nenhum",
    },
    {
      path: "/pizza-vendas",
      component: ReaLinaBTFlex,
      layout: "nenhum"
    },
    {
      path: "/cinema-resultados",
      component: CinemaResultadosBTFlex,
      layout: "nenhum",
    },
    {
      path: "/fluxo-caixa-projetado",
      component: ProjecaoResultadosBTFlex,
      layout: "nenhum",
    },
    {
      path: "/cachoeira",
      component: GraficoCachoeiraBTFlex,
      layout: "nenhum"
    },
    {
      path: "/timtim-da-contribuicao",
      component: CoposContribuicaoBTFlex,
      layout: "nenhum",
    },
    {
      path: "/mapa-despesas",
      component: XptoBTFlex,
      layout: "nenhum"
    },
    {
      path: "/saldo-contas",
      component: SaldosContasBTFlex,
      layout: "nenhum"
    },
    {
      path: "/fluxo-caixa",
      component: FluxoCaixaBTFlex,
      layout: "nenhum"
    },
    {
      path: "/vendas-diarias",
      component: GridVendasUnidadesDiariasBTFlex,
      layout: "nenhum",
    },
    {
      path: "/matrix",
      component: TopFamiliasOnDemandBTFlex,
      layout: "nenhum"
    },
    {
      path: "/custo-producao",
      component: CustoProducaoBTFlex,
      layout: "nenhum"
    }, 
    {
      path: "/evolucao-despesas",
      component: GraficoEvolucaoDespesasBTFlex,
      layout: "nenhum"
    },
    { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> }
  ],
  real: [
    { path: "/dashboard", component: () => <Blank /> },
    { path: "/fluxo-caixa", component: FluxoCaixa, layout: "nenhum" },
    {
      path: "/mapa-de-negocios",
      component: MapaNegociosUnidades,
      layout: "nenhum",
    },
    { path: "/bizoiada", component: RaioXLoja, layout: "nenhum" },
    {
      path: "/vaobora-olha-a-hora",
      component: GraficoHoraLojas,
      layout: "nenhum",
    },
    { path: "/amchart", component: AmchartPagina, layout: "nenhum" },
    { path: "/ritmo-do-negocio", component: Diario, layout: "nenhum" },
    {
      path: "/evolucao-despesas",
      component: GraficoEvolucaoDespesas,
      layout: "nenhum",
    },
    { path: "/batida-do-tambor", component: GraficoUnidades, layout: "nenhum" },
    { path: "/cachoeira", component: GraficoCachoeira, layout: "nenhum" },
    {
      path: "/vendas-diarias",
      component: GridVendasUnidadesDiarias,
      layout: "nenhum",
    },
    { path: "/arvore", component: GraficoArvore, layout: "nenhum" },
    { path: "/meu-perfil", component: MeuPerfil },
    {
      path: "/tabuleiro-de-negocios",
      component: TabuleiroNegocios,
      layout: "nenhum",
    },
    { path: "/os-sujeitos", component: TopSujeitos, layout: "nenhum" },
    {
      path: "/cinema-resultados",
      component: cinemaResultados,
      layout: "nenhum",
    },
    { path: "/matrix", component: TopFamiliasOnDemand, layout: "nenhum" },
    { path: "/saldo-contas", component: SaldosContas, layout: "nenhum" },
    { path: "/realina", component: ReaLina, layout: "nenhum" },
    {
      path: "/timtim-da-contribuicao",
      component: CoposContribuicao,
      layout: "nenhum",
    },
    {
      path: "/indicadores-de-vendas/estagios",
      component: IndicadoresVendaEstagios,
      layout: "nenhum",
    },
    {
      path: "/indicadores-de-vendas/listagemPedidosEstagios/:mes/:ano/:status/:estagio",
      component: ListagemPedidosEstagios,
      layout: "nenhum",
    },
    {
      path: "/indicadores-de-vendas/vendedores",
      component: Vendedores,
      layout: "nenhum",
    },
    {
      path: "/indicadores-de-vendas/desempenho-do-vendedor/",
      component: DesempenhoVendedor,
      layout: "nenhum",
    },
    { path: "/ranking-sujeitos", component: TopProdutos, layout: "nenhum" },
    {
      path: "/ranking-sujeitos/filtrado",
      component: RankingProdutos,
      layout: "nenhum",
    },
    { path: "/mapa-despesas", component: Xpto, layout: "nenhum" },
    { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
  ],
  benassi: [
    { path: "/dashboard", component: () => <Blank /> },
    { path: "/fluxo-caixa", component: FluxoCaixa, layout: "nenhum" },
    {
      path: "/mapa-de-negocios",
      component: MapaNegociosUnidades,
      layout: "nenhum",
    },
    { path: "/bizoiada", component: RaioXLoja, layout: "nenhum" },
    {
      path: "/vaobora-olha-a-hora",
      component: GraficoHoraLojas,
      layout: "nenhum",
    },
    { path: "/amchart", component: AmchartPagina, layout: "nenhum" },
    { path: "/ritmo-do-negocio", component: Diario, layout: "nenhum" },
    {
      path: "/evolucao-despesas",
      component: GraficoEvolucaoDespesas,
      layout: "nenhum",
    },
    { path: "/batida-do-tambor", component: GraficoUnidades, layout: "nenhum" },
    { path: "/resultado", component: GraficoCachoeira, layout: "nenhum" },
    {
      path: "/vendas-diarias",
      component: GridVendasUnidadesDiarias,
      layout: "nenhum",
    },
    { path: "/arvore", component: GraficoArvore, layout: "nenhum" },
    { path: "/meu-perfil", component: MeuPerfil },
    {
      path: "/tabuleiro-de-negocios",
      component: GridMargemNegocio,
      layout: "nenhum",
    },
    { path: "/os-sujeitos", component: TopSujeitos, layout: "nenhum" },
    {
      path: "/cinema-resultados",
      component: cinemaResultados,
      layout: "nenhum",
    },

    { path: "/filtro/:pagina", component: PreFiltroProdutos },
    { path: "/filtro-unidades/:pagina", component: PreFiltroUnidades },

    { path: "/matrix-de-negocios", component: GridMargemFilial, layout: "nenhum" },

    { path: "/matrix-de-produtos", component: GridMargemProduto, layout: "nenhum" },

    { path: "/matrix", component: TopFamiliasOnDemand, layout: "nenhum" },
    { path: "/saldo-contas", component: SaldosContas, layout: "nenhum" },
    { path: "/desempenho-negocio", component: ReaLina, layout: "nenhum" },
    {
      path: "/zoom-de-desempenho",
      component: CoposContribuicao,
      layout: "nenhum",
    },
    {
      path: "/indicadores-de-vendas/estagios",
      component: IndicadoresVendaEstagios,
      layout: "nenhum",
    },
    {
      path: "/indicadores-de-vendas/listagemPedidosEstagios/:mes/:ano/:status/:estagio",
      component: ListagemPedidosEstagios,
      layout: "nenhum",
    },
    {
      path: "/indicadores-de-vendas/vendedores",
      component: Vendedores,
      layout: "nenhum",
    },
    {
      path: "/indicadores-de-vendas/desempenho-do-vendedor/",
      component: DesempenhoVendedor,
      layout: "nenhum",
    },
    { path: "/ranking-sujeitos", component: TopProdutos, layout: "nenhum" },
    {
      path: "/ranking-sujeitos/filtrado",
      component: RankingProdutos,
      layout: "nenhum",
    },
    { path: "/mapa-despesas", component: Xpto, layout: "nenhum" },
    { path: "/painel-reposicao-lojas", component: PainelReposicaoLojas, layout: "nenhum" },
    { path: "/painel-reposicao-produtos", component: PainelReposicaoProdutos, layout: "nenhum" },
    { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
  ],
  capitalRelacional: [
    { path: "/dashboard", component: () => <Blank /> },
    {
      path: "/painel-das-suplicas",
      component: GridAgentesHolograma,
      layout: "nenhum",
    },
    {
      path: "/indicadores-relacionais",
      component: GridRelacaoOnDemanda,
      layout: "nenhum",
    },
    {
      path: "/quadrantes-de-oportunidades",
      component: GraficoReviravoltaAia,
      layout: "nenhum",
    },
    {
      path: "/camadas-de-negocio",
      component: GridPainelCamadasNegocio,
      layout: "nenhum",
    },
    {
      path: "/nuvem-de-oportunidades",
      component: GraficoPainelRelacional,
      layout: "nenhum"
    },
    {
      path: "/boa-conversa",
      component: BoaConversa,
      layout: "nenhum"
    },
    {
      path: "/mapa-de-calor",
      component: GraficoAgora,
      layout: "nenhum"
    },
    {
      path: "/suplicas-em-destaque",
      component: GraficoVisaoSuplicas,
      layout: "nenhum"
    },
    {
      path: "/indicador-de-cultura",
      component: GraficoFormulaLucro,
      layout: "nenhum"
    },
    {
      path: "/historico-relacional",
      component: GraficoEvolucaoRelacinal,
      layout: "nenhum"
    },
    { path: "/meu-perfil", component: MeuPerfil },
    // {
    //   path: "/dashboard",
    //   component: () => <GridAgentesHolograma />,
    //   layout: "nenhum",
    // },
    // {
    //   path: "/",
    //   component: () => <GridAgentesHolograma />,
    //   layout: "nenhum",
    // },
    // {
    //   path: "/relacao",
    //   component: () => <GridRelacao />,
    //   layout: "nenhum",
    // },
    // {
    //   path: "/relacao-on-demanda",
    //   component: () => <GridRelacaoOnDemanda />,
    //   layout: "nenhum",
    // },
    // {
    //   path: "/grafico",
    //   component: () => <GraficoReviravoltaAia />,
    //   layout: "nenhum",
    // },
    { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
  ],
};

export { rotas };
