import cliente from ".";

export const retornarIdCliente = () => {
    switch (cliente.id) {
        case "real":
            return "real";
        case "benassi":
            return "benassi";
        case "btflex":
            return "btflex";
    }
}