import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { retornarTipoCustos, exportarExcel, Hoje, toMoney, primeiroDiaMes, toDateSQL, Mes, valorEmInteiro, removerFocusNoBotao } from "../../helpers/utils";
import PaginaLoader from '../../components/loader/pagina';
import { useDispatch, useSelector } from 'react-redux';
import { requisitarDados, requisitarLimparDados } from "../../store/graficoUnidades/actions";
import { requisitarDadosAmbiente } from "../../store/ambiente/actions";
import Header from "../../components/HorizontalLayout/HeaderApp";
import { FormGroup, Button, Form, Container, } from 'reactstrap';
import Grafico from "./partes/grafico";
import GraficoBenassi from "./partes/graficoBenassi";
import HeaderGraficoBenassi from '../../components/headerGraficoBenassi';
import Flatpickr from "react-flatpickr";
import { Portuguese } from "../../helpers/locale/portuguese";
import { pegarCores, returnaAlturaTotalElementos, returnaTotalMedidasVerticais } from '../../helpers/utils';
import HeaderGrafico from '../../components/headerGrafico';
import FiltroCasca from '../../components/filtroGrafico/filtroCasca';
import GraficoAltura from '../../components/graficoAltura';
import FocarPaisagem from '../../components/forcarPaisagem';
import { retornarIdCliente } from '../../cliente/variaveis';
import Select from 'react-select';
import FiltroBrytum from "../../components/filtroBrytum";
import { retornaExportacaoExcel } from './partes/variaveis';

import makeAnimated from 'react-select/animated';
const idCliente = retornarIdCliente();
const tiposCusto = retornarTipoCustos();
const GraficoUnidades = () => {
  const [mostrarFiltros, setMostrarFiltros] = useState(false);
  const carregandoMesAtual = useSelector(state => state.MapaNegociosUnidades.carregando);
  const { dados, carregando } = useSelector(state => state.GraficoUnidades);
  const [cores, seTcores] = useState(pegarCores());
  const [dataInicial, setDataInicial] = useState(primeiroDiaMes(Mes()));
  const [dataFinal, setDataFinal] = useState(Hoje());
  const dispatch = useDispatch();
  const [mostrarTotais, setMostrarTotais] = useState(false);
  const token = useSelector(state => state.Login.user.tokenNOL);
  const [tipoCusto, setTipoCusto] = useState(tiposCusto[1]);
  const [propTipoCusto, setPropTipoCusto] = useState(tiposCusto[1].value == "02" ? "02_" : "");
  const dataInicialRef = useRef(null);
  dataInicialRef.current = { dataInicial, setDataInicial };

  const dataFinalRef = useRef(null);
  dataFinalRef.current = { dataFinal, setDataFinal };


  const atualizarDados = async () => {
    if (!dataInicialRef.current.dataInicial || !dataFinalRef.current.dataFinal) return

    await dispatch(requisitarDados({
      data_inicial: toDateSQL(dataInicialRef.current.dataInicial),
      data_final: toDateSQL(dataFinalRef.current.dataFinal),
      arvore: 1,
      visualizacao: "unidade",
      agrupamento: "total",
      exportacao: retornaExportacaoExcel(tipoCusto.value),
      retornaMC3: 1,
      retorna_desafio: idCliente == "benassi" ? 1 : "",
      tipo_cmv: tipoCusto.value == "01" ? "medio" : "gerencial",
      base_calculo_percentual: idCliente == "benassi" ? "fixa" : "",
      token
    }
    ));
    setMostrarFiltros(false);
  }

  const atualizarDadosAmbiente = async () => {
    await dispatch(requisitarDadosAmbiente(token));
  }
  useEffect(() => {
    atualizarDadosAmbiente();
    atualizarDados();
    return () => {
      dispatch(requisitarLimparDados())
    }
  }, []);

  useEffect(() => {
    setMostrarTotais(!carregando && (dados && dados.data && dados.data.length));
  }, [carregando, dados])

  useEffect(() => {
    if (dados) {
      setPropTipoCusto(tipoCusto.value == "02" ? "02_" : "");
    }
  }, [dados]);

  useLayoutEffect(() => {
    var objeto = document.querySelector("#margem");
    setTimeout(() => {
      let alturaGrafico = "";
      if (mostrarFiltros)
        alturaGrafico = returnaAlturaTotalElementos(["#page-topbar", ".calcularaltura"]) + returnaTotalMedidasVerticais([".cardbodygrafico"]) + 10;
      else
        alturaGrafico = returnaAlturaTotalElementos(["#page-topbar", ".calcularaltura"]) + returnaTotalMedidasVerticais([".cardbodygrafico"]) + 35;

      let alturaFinal = ""
      if (window.outerHeight < 600) {
        document.querySelector("#layout-wrapper").style.overflow = "auto";
        alturaGrafico = alturaGrafico * 4;
        alturaFinal = `calc(100vh)`;
      } else {
        document.querySelector("#layout-wrapper").style.overflow = "hidden";
        alturaFinal = `${document.querySelector("body").clientHeight - alturaGrafico}px`;
      }
      objeto.style.minHeight = alturaFinal;
      objeto.style.maxHeight = alturaFinal;
      objeto.style.height = alturaFinal;
    }, 500);
  }, [mostrarFiltros])



  const trocarTipoCusto = (valor) => {
    if (valor) {
      setTipoCusto(valor)
    } else {
      setTipoCusto([])
    }
  }

  return (
    <React.Fragment>
      <FocarPaisagem />
      <PaginaLoader carregando={carregando || carregandoMesAtual} />
      <>
        <Header
          parametros={
            {
              exportarExcel: () => exportarExcel(dados),
              botoesExtras: [
                {
                  funcao: () => {
                    setMostrarFiltros(!mostrarFiltros)
                  },
                  icone: "bx bx bx-filter-alt"
                }
              ]
            }
          } />
        <div id="layout-wrapper">
          <div className="calcularaltura">
            <FiltroBrytum aberto={mostrarFiltros}>
              <Form inline>
                <FormGroup style={{ width: 110 }}>
                  <Flatpickr
                    className="form-control d-block w-100"
                    placeholder="dd M,yyyy"
                    locale="pt"
                    options={{
                      mode: "single",
                      locale: Portuguese,
                      dateFormat: "d/m/Y",
                      maxDate: `today`,
                      defaultDate: dataInicial,
                      disableMobile: "true"
                    }}
                    onChange={(selectedDates, dateStr, instance) => {
                      if (selectedDates.length > 0) {
                        setDataInicial(selectedDates[0]);
                      }
                    }}
                  />
                </FormGroup>
                <div className={"ml-1 mr-1"} >a</div>
                <FormGroup style={{ width: 110 }}>
                  <Flatpickr
                    className="form-control d-block w-100"
                    placeholder="dd M,yyyy"
                    locale="pt"
                    options={{
                      mode: "single",
                      locale: Portuguese,
                      dateFormat: "d/m/Y",
                      maxDate: `today`,
                      defaultDate: "today",
                      disableMobile: "true"
                    }}
                    onChange={(selectedDates, dateStr, instance) => {
                      if (selectedDates.length > 0) {
                        setDataFinal(selectedDates[0]);
                      }
                    }}
                  />
                </FormGroup>
                {idCliente == "benassi" &&
                  <FormGroup className="ml-2">
                    <div className="unidadesControler">
                      <Select
                        placeholder=""
                        className="basic-single"
                        options={tiposCusto}
                        isSearchable={false}
                        classNamePrefix="select2-selection"
                        defaultValue={tiposCusto[1] || []}
                        onChange={Object => {
                          trocarTipoCusto(Object || []);
                        }}
                      />
                    </div>
                  </FormGroup>
                }
                <FormGroup>
                  <Button color="light" onClick={(e) => { atualizarDados(); removerFocusNoBotao(e.currentTarget); }} className="ml-2">
                    <i className="fal fa-hashtag"></i>
                    boralá!
                  </Button>
                </FormGroup>
              </Form>
            </FiltroBrytum>
            <Container className="fixWith" fluid>
              {idCliente == "benassi" &&
                <div className={"headers mt-4"}>

                  <HeaderGraficoBenassi mostrar={mostrarTotais} colunas={2} itens={[
                    {
                      nome: "Período",
                      valor: dados?.header?.periodo_data,
                      periodo: true,
                      corClass: ""
                    },
                    {
                      valor: dados?.footer.faturamento_liquido,
                      nome: dados?.header?.faturamento_liquido,
                      realizado_percentual: dados?.footer.faturamento_liquido_percentual == 0 ? `-` : `${toMoney(dados?.footer.faturamento_liquido_percentual, 1)}%`,
                      necessario_percentual: dados?.footer?.desafio_percentual == 0 ? `-` : `${toMoney(dados?.footer.desafio_percentual, 1)}%`,
                      realizado_valor: dados?.footer?.faturamento_liquido == 0 ? `-` : `${valorEmInteiro(dados?.footer.faturamento_liquido, false)}`,
                      necessario_valor: dados?.footer?.desafio_valor == 0 ? `-` : `${toMoney(dados?.footer?.desafio_valor, 0)}`,
                      variacao: dados?.footer?.desafio_variacao_percentual,
                      corClass: "text-info",
                      periodo: false,
                      faturamento: false
                    },
                    // {
                    //   valor: dados?.footer.cmv_valor,
                    //   nome: dados?.header?.cmv_valor,
                    //   realizado_percentual: dados?.footer.cmv_percentual == 0 ? `-` : `${toMoney(dados?.footer.cmv_percentual, 1)}%`,
                    //   necessario_percentual: dados?.footer?.desafio_cmv_percentual == 0 ? `-` : `${toMoney(dados?.footer.desafio_cmv_percentual, 1)}%`,
                    //   realizado_valor: dados?.footer?.cmv_valor == 0 ? `-` : `${valorEmInteiro(dados?.footer.cmv_valor, false)}`,
                    //   necessario_valor: dados?.footer?.desafio_cmv_valor == 0 ? `-` : `${toMoney(dados?.footer?.desafio_cmv_valor, 0)}`,
                    //   variacao: dados?.footer?.desafio_cmv_variacao_percentual,
                    //   corClass: "text-danger",
                    //   periodo: false,
                    //   faturamento: false
                    // },
                    {
                      valor: dados?.footer.margem_contribuicao_valor,
                      nome: dados?.header?.margem_contribuicao_valor,
                      realizado_percentual: dados?.footer.margem_contribuicao_percentual == 0 ? `-` : `${toMoney(dados?.footer.margem_contribuicao_percentual, 1)}%`,
                      necessario_percentual: dados?.footer?.desafio_margem_i_percentual == 0 ? `-` : `${toMoney(dados?.footer.desafio_margem_i_percentual, 1)}%`,
                      realizado_valor: dados?.footer?.margem_contribuicao_valor == 0 ? `-` : `${valorEmInteiro(dados?.footer.margem_contribuicao_valor, false)}`,
                      necessario_valor: dados?.footer?.desafio_margem_i_valor == 0 ? `-` : `${toMoney(dados?.footer?.desafio_margem_i_valor, 0)}`,
                      variacao: dados?.footer?.desafio_margem_i_variacao_percentual,
                      corClass: "text-success",
                      periodo: false,
                      faturamento: false
                    },
                    // {
                    //   valor: dados?.footer.despesas_unidade,
                    //   nome: dados?.header?.despesas_unidade,
                    //   realizado_percentual: dados?.footer.despesas_unidade_percentual == 0 ? `-` : `${toMoney(dados?.footer.despesas_unidade_percentual, 1)}%`,
                    //   necessario_percentual: dados?.footer?.desafio_despesa_percentual == 0 ? `-` : `${toMoney(dados?.footer.desafio_despesa_percentual, 1)}%`,
                    //   realizado_valor: dados?.footer?.despesas_unidade == 0 ? `-` : `${valorEmInteiro(dados?.footer.despesas_unidade, false)}`,
                    //   necessario_valor: dados?.footer?.desafio_despesa_valor == 0 ? `-` : `${toMoney(dados?.footer?.desafio_despesa_valor, 0)}`,
                    //   variacao: dados?.footer?.desafio_despesa_variacao_percentual,
                    //   corClass: "text-danger",
                    //   periodo: false,
                    //   faturamento: false
                    // },
                    {
                      valor: dados?.footer.margem_ii,
                      nome: dados?.header?.margem_ii,
                      realizado_percentual: dados?.footer.margem_ii_percentual == 0 ? `-` : `${toMoney(dados?.footer.margem_ii_percentual, 1)}%`,
                      necessario_percentual: dados?.footer?.desafio_margem_ii_percentual == 0 ? `-` : `${toMoney(dados?.footer.desafio_margem_ii_percentual, 1)}%`,
                      realizado_valor: dados?.footer?.margem_ii == 0 ? `-` : `${valorEmInteiro(dados?.footer.margem_ii, false)}`,
                      necessario_valor: dados?.footer?.desafio_margem_ii_valor == 0 ? `-` : `${toMoney(dados?.footer?.desafio_margem_ii_valor, 0)}`,
                      variacao: dados?.footer?.desafio_margem_ii_variacao_percentual,
                      corClass: "text-success",
                      periodo: false,
                      faturamento: false
                    }
                  ]} />

                </div>
              }
              {
                idCliente == "real" &&
                <div className={"headers mt-4"}>
                  {
                    <HeaderGrafico mostrar={mostrarTotais} colunas={3} itens={[
                      {
                        nome: dados?.header?.cupons,
                        valor: valorEmInteiro(dados?.footer?.cupons),
                        marginRight: 30,
                        corClass: "text-info"
                      },
                      {
                        nome: dados?.header?.faturamento,
                        valor: `R$ ${toMoney(dados?.footer?.faturamento, 0)}`,
                        marginRight: 30,
                        corClass: "text-success"
                      },
                      {
                        nome: dados?.header?.cmv_valor,
                        valor: `${toMoney(dados?.footer?.cmv_percentual, 1)}%`,
                        segundoValor: `${valorEmInteiro(dados?.footer?.cmv_valor, false)}`,
                        corClass: "text-danger"
                      },
                      {
                        nome: dados?.header?.margem_contribuicao_valor,
                        valor: `${toMoney(dados?.footer?.margem_contribuicao_percentual, 1)}%`,
                        segundoValor: `${valorEmInteiro(dados?.footer?.margem_contribuicao_valor, false)}`,
                        corClass: "text-dark"
                      }
                    ]} />}
                </div>
              }
            </Container>
          </div>



          <GraficoAltura>
            {idCliente == "real" && <Grafico index={"margem"} dados={dados} cores={cores} />}
            {idCliente == "benassi" && <GraficoBenassi index={"margem"} dados={dados} cores={cores} />}
          </GraficoAltura>


        </div>
      </>
    </React.Fragment>
  );
}
export default GraficoUnidades;