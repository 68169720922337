import React, {useState} from 'react';
import { Row, Col, Alert, Card, CardBody,Container, Input, Label } from "reactstrap";

// Redux
import { connect, useDispatch } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from "availity-reactstrap-validation";

import {Auth} from "aws-amplify"

import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

import HeaderAuth from './partes/headerAutenticacao';
import FooterLogin from "./partes/footer"
import AuthCode from "react-auth-code-input"
// action
import { userForgetPassword } from "../../store/actions";
import FooterMsg from "./footerMsg"
import LogoCliente from "./partes/logoCliente"
// import images
import zib from "../../assets/images/profile-img.png";

  const EsqueciMinhaSenha = (props) => {
    const dispatch = useDispatch();
    const [code, setarCode]         = useState(false);
    const [codigoVerificacao, setarCodigiVerificacao] = useState("");
    const [username, setatrUser]    = useState("");
    const [password, setarPassword] = useState("");
    const [confirmarcaoSenha, setConfirmarcaoSenha] = useState("")
    async function handleValidSubmit(event, values) {

      await Auth.forgotPassword(values.email.toLowerCase().trim() )
        .then(data => {
            dispatch({type: "CLEAN_API_MESSAGE", payload: ""})
            setarCode(data.CodeDeliveryDetails)
            setatrUser(values.email.trim())
        })
        .catch(err => {
          console.log(err)
          if (! err.message) {
            toastr.error("Usuário não encontrado.!")
          } else {
            if(err.message === "Attempt limit exceeded, please try after some time."){
              console.log(err.message === "Attempt limit exceeded, please try after some time")
              return toastr.error("Tente novamento em alguns minutos.", "Você tentou recuperar sua senha muitas vezes")

            }
            toastr.error("Usuário não encontrado.!", "Nenhum usuário com essa combinação")
          }
        })
    }

  async function reenViar() {

    await Auth.forgotPassword(username.toLowerCase().trim() )
    .then(data => {
      console.log(data)
        toastr.success("Codigo reenviado!", "")
        setarCode(data.CodeDeliveryDetails)
    })
    .catch(err => {
      console.log(err)
      if (! err.message) {
        toastr.success("Usuário não encontrado.!")
      } else {
        if(err.message === "Attempt limit exceeded, please try after some time."){
          console.log(err.message === "Attempt limit exceeded, please try after some time")
          return toastr.error("Tente novamento em alguns minutos.", "Você tentou recuperar sua senha muitas vezes")

        }
        toastr.error("Usuário não encontrado.!", "Nenhum usuário com essa combinação")
      }
    })
  }

  const forgotPasswordSubmit = async () =>{
      if(!codigoVerificacao || !password)
          return toastr.error("Por favor preencha os campos para continuar.", "Erro!")

      if(password !== confirmarcaoSenha)
          return toastr.error("Senha e sua confirmação estão diferentes", "Erro!")
      await Auth.forgotPasswordSubmit(username.toLowerCase().trim(), codigoVerificacao, password)
      .then(() => {
        toastr.success("Nova senha configurada com sucesso.", "Senha alterada!")
        props.history.push('/login')
        console.log('the New password submitted successfully')
      })
      .catch(err => {
        
        if (! err.message) {
          if(err.message ===  "Invalid verification code provided, please try again."){
            return toastr.error("Erro!", "Codigo de verificação incorreto.")
          }
          toastr.error("Erro!", "Erro ao confirmar nova senha")

        } else {
          toastr.error("Erro!", "Erro ao confirmar nova senha")
        }
      })
    }
    return (
        <React.Fragment>
        <div className="account-pages">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <HeaderAuth titulo="Esqueci minha senha." subtitulo="Recupere sua senha."  imagem={zib}/>
                  <CardBody className="pt-0">
                    <LogoCliente />
                    <div className="p-2">

                      {props.forgetError && props.forgetError ? (
                        <Alert color="danger" style={{ marginTop: "13px" }}>
                          {props.forgetError}
                        </Alert>
                      ) : null}
                      {code ? (
                        <Alert color="success" style={{ marginTop: "13px" }}>
                          {`Por favor digite o código de verificação enviado para ${code.Destination || `****`} `}
                        </Alert>
                      ) : null}


                        {code ?
                        <>
                        <div className="authcode mb-2">
                        <AuthCode
                              characters={6}
                              onChange={(e)=> {
                                setarCodigiVerificacao(e)
                              }}
                              className="form-control form-control-lg text-center d-flex"
                              allowedCharacters="^[0-9]"
                              inputStyle={{
                                width: "47px",
                                height: "42px",
                                padding: "10px",
                                borderRadius: "8px",
                                fontSize: "16px",
                                textAlign: "center",
                                border: "1px solid #ced4da",
                                textTransform: "uppercase",
                              }}
                            />
                        </div>
                        <hr />
                        <div className=" mb-3">
                          <Label>nova senha</Label>
                          <Input
                            className="form-control"
                            placeholder="*******"
                            type="password"
                            autoComplete="off"
                            onChange={(e)=> setarPassword(e.target.value)}
                          />
                        </div>
                        <div className=" mb-3">
                          <Label>confirmar senha</Label>
                          <Input
                            className="form-control"
                            placeholder="*******"
                            type="password"
                            autoComplete="off"
                            onChange={(e)=> setConfirmarcaoSenha(e.target.value)}
                          />
                          
                        </div>
                        <Row>
                          <Col className="text-right">
                              <button
                                  onClick={reenViar}
                                  className="btn btn-primary w-md waves-effect waves-light mr-2"
                                >
                                  reenviar código
                                </button>
                              <button
                                className="btn btn-primary w-md waves-effect waves-light"
                                onClick={forgotPasswordSubmit}
                              >
                                Enviar
                                </button>
                            </Col>
                        </Row>
                        </> : 
                            <AvForm
                            className="form-horizontal mt-1"
                            onValidSubmit={(e,v) => handleValidSubmit(e,v)}
                          >
                          <div className="form-group">
                            <AvField
                              name="email"
                              label="Email"
                              className="form-control"
                              placeholder="Seu e-mail"
                              type="email"
                              errorMessage="Campo inválido ou não é um e-mail."
                              required
                            />

                          </div>
                          <Row className="form-group">
                            <Col className="text-right">
                              <button
                                className="btn btn-primary w-md waves-effect waves-light"
                                type="submit"
                              >
                                Alterar
                                </button>
                            </Col>
                          </Row>
                          </AvForm>
                        }


                    </div>
                  </CardBody>
                </Card>
                <div className="mt-2 text-center">
                  <p>
                    Voltar para {" "}
                    <Link
                      to="login"
                      className="font-weight-medium text-primary"
                    >
                      Login
                      </Link>{" "}
                  </p>
                </div>
                <FooterMsg />
              </Col>
            </Row>
          </Container>
        </div>
        <FooterLogin />
      </React.Fragment>
      );
    }

const mapStatetoProps = state => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword;
  return { forgetError, forgetSuccessMsg };
};

export default withRouter(
  connect(mapStatetoProps, { userForgetPassword })(EsqueciMinhaSenha)
);
