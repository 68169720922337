import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { retornarLiquidoBruto, retornarTipoCustos, returnaAlturaTotalElementos, returnaTotalMedidasVerticais, exportarExcel, concatenarDropdown, retornarAnos, retornarMeses, concatenaLojas, Hoje, primeiroDiaMes, Mes, removerFocusNoBotao } from "../../helpers/utils"
import PaginaLoader from '../../components/loader/pagina';
import { useDispatch, useSelector } from 'react-redux'
import { requisitarDados, requisitarLimparDados } from "../../store/graficoCachoeira/actions"
import { requisitarDadosAmbiente } from "../../store/ambiente/actions"
import Header from "../../components/HorizontalLayout/HeaderApp"
import { FormGroup, Button, Form, Container, Col, Row, Card, CardBody } from 'reactstrap';
import Grafico from "./partes/grafico"
import GraficoBenassi from "./partes/graficoBenassi";
import makeAnimated from 'react-select/animated';
import { pegarCores } from '../../helpers/utils';
import FiltroCasca from '../../components/filtroGrafico/filtroCasca';
import GraficoAltura from '../../components/graficoAltura';
import Select from 'react-select';
import FocarPaisagem from '../../components/forcarPaisagem';
import { retornarIdCliente } from '../../cliente/variaveis';
import FiltroBrytum from "../../components/filtroBrytum";
const animatedComponents = makeAnimated();
const anos = retornarAnos(6);
const meses = retornarMeses();
const idCliente = retornarIdCliente();
const tiposCusto = retornarTipoCustos();
const opcoesLiquidoBruto = retornarLiquidoBruto();

const GraficoCachoeira = (props) => {
  const carregandoMesAtual = useSelector(state => state.MapaNegociosUnidades.carregando);
  const { dados, carregando } = useSelector(state => state.GraficoCachoeira);
  const [dataInicial, setDataInicial] = useState(primeiroDiaMes(Mes()));
  const [dataFinal, setDataFinal] = useState(Hoje());
  const dispatch = useDispatch();
  const { lojasDoUsuario } = useSelector(state => state.Ambiente);
  const [lojas, setLojas] = useState([]);
  const [mesesSelecionados, setMesesSelecionados] = useState(meses.filter(a => a.value == Mes(Hoje())));
  const [ano, setAno] = useState(anos[0]);
  const [mostrarFiltros, setMostrarFiltros] = useState(false);
  const [tipoCusto, setTipoCusto] = useState(tiposCusto[1]);
  const [liquidoBruto, setLiquidoBruto] = useState(opcoesLiquidoBruto[2]);
  const token = useSelector(state => state.Login.user.tokenNOL);

  const mesesSelecionadosRef = useRef(null);
  mesesSelecionadosRef.current = { mesesSelecionados, setMesesSelecionados };

  const lojasRef = useRef(null);
  lojasRef.current = { lojas, setLojas };

  const anoRef = useRef(null);
  anoRef.current = { ano, setAno };


  const atualizarDados = async () => {

    await dispatch(requisitarDados({
      ano: ano.value,
      meses: mesesSelecionadosRef.current.mesesSelecionados.length ? concatenarDropdown(mesesSelecionadosRef.current.mesesSelecionados) : concatenarDropdown(meses),
      arvore: 1,
      visualizacao: "unidade",
      agrupamento: "total",
      u_unidades_id: concatenaLojas(lojasRef.current.lojas),
      retornaMC3: 1,
      token,
      exportacao: idCliente == "benassi" ? "resultado" : "batida_tambor",
      tipo_cmv: tipoCusto.value == "01" ? "medio" : "gerencial",
      base_calculo_percentual: idCliente == "benassi" ? "bruto" : null,
      insere_despesas_apoio: idCliente == "benassi" ? 1 : null,
      base_calculo_percentual: "fixa"
    }
    ));
    setMostrarFiltros(false);
  }
  const trocarAno = (valor) => {
    if (valor) {
      setAno(valor)
    } else {
      setAno([])
    }
  }
  const atualizarDadosAmbiente = async () => {

    await dispatch(requisitarDadosAmbiente(token));
  }
  useEffect(() => {
    atualizarDadosAmbiente();
    atualizarDados();
    return () => {
      dispatch(requisitarLimparDados())
    }
  }, []);

  const trocarLojas = (valor) => {
    if (valor) {
      setLojas(valor)
    } else {
      setLojas([])
    }
  }

  const trocarMes = (valor) => {
    if (valor) {
      setMesesSelecionados(valor)
    } else {
      setMesesSelecionados([])
    }
  }

  const trocarTipoCusto = (valor) => {
    if (valor) {
      setTipoCusto(valor)
    } else {
      setTipoCusto([])
    }
  }

  const trocarLiquidoBruto = (valor) => {
    if (valor) {
      setLiquidoBruto(valor)
    } else {
      setLiquidoBruto([])
    }
  }

  useLayoutEffect(() => {
    var objeto = document.querySelector("#chartdiv");
    setTimeout(() => {
      let alturaGrafico = "";
      if (mostrarFiltros)
        alturaGrafico = returnaAlturaTotalElementos(["#page-topbar", ".calcularaltura"]) + returnaTotalMedidasVerticais([".cardbodygrafico"]) + 30;
      else
        alturaGrafico = returnaAlturaTotalElementos(["#page-topbar", ".calcularaltura"]) + returnaTotalMedidasVerticais([".cardbodygrafico"]) + 30;

      let alturaFinal = ""
      if (window.outerHeight < 600) {
        document.querySelector("#layout-wrapper").style.overflow = "auto";
        alturaGrafico = alturaGrafico * 4;
        alturaFinal = `calc(100vh)`;
      } else {
        document.querySelector("#layout-wrapper").style.overflow = "hidden";
        alturaFinal = `${document.querySelector("body").clientHeight - alturaGrafico}px`;
      }
      objeto.style.minHeight = alturaFinal;
      objeto.style.maxHeight = alturaFinal;
      objeto.style.height = alturaFinal;
    }, 500);
  }, [mostrarFiltros])
  return (
    <React.Fragment>
      <FocarPaisagem />
      <PaginaLoader carregando={carregando || carregandoMesAtual} />
      <>
        <Header
          parametros={
            {
              exportarExcel: () => exportarExcel(dados),
              botoesExtras: [
                {
                  funcao: () => {
                    setMostrarFiltros(!mostrarFiltros)
                  },
                  icone: "bx bx bx-filter-alt"
                }
              ]
            }
          } />
        <div id="layout-wrapper">
          <div className={"calcularaltura"}>
            <FiltroBrytum aberto={mostrarFiltros}>
              <Form inline>
                <FormGroup className="select2-container" >
                  <div className="unidadesControler">
                    <Select
                      placeholder="Todas as unidades"
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      options={lojasDoUsuario}
                      isSearchable={false}
                      isMulti
                      classNamePrefix="select2-selection"
                      defaultValue={lojas || []}
                      onChange={Object => {
                        trocarLojas(Object || []);
                      }}
                    />
                  </div>
                </FormGroup>
                <FormGroup className="ml-2">
                  <div className="unidadesControler anoControler">
                    <Select
                      placeholder="Todas as unidades"
                      className="basic-single"
                      options={anos}
                      isSearchable={false}
                      classNamePrefix="select2-selection"
                      defaultValue={anos[0] || []}
                      onChange={Object => {
                        trocarAno(Object || []);
                      }}
                    />
                  </div>
                </FormGroup>
                <FormGroup className="select2-container ml-2"  >
                  <div className="unidadesControler">
                    <Select
                      defaultValue={meses.filter(a => a.value == Mes(Hoje()))}
                      isMulti
                      name="meses"
                      options={meses}
                      placeholder="Todos os meses"
                      closeMenuOnSelect={false}
                      classNamePrefix="select2-selection"
                      isSearchable={false}
                      onChange={Object => {
                        trocarMes(Object || []);
                      }}
                    />
                  </div>
                </FormGroup>
                {idCliente == "benassi" &&
                  <FormGroup className="ml-2">
                    <div className="unidadesControler">
                      <Select
                        placeholder=""
                        className="basic-single"
                        options={tiposCusto}
                        isSearchable={false}
                        classNamePrefix="select2-selection"
                        defaultValue={tiposCusto[1] || []}
                        onChange={Object => {
                          trocarTipoCusto(Object || []);
                        }}
                      />
                    </div>
                  </FormGroup>
                }
                {idCliente == "benassi" &&
                  <FormGroup className="ml-2">
                    <div className="unidadesControler">
                      <Select
                        placeholder=""
                        className="basic-single"
                        options={opcoesLiquidoBruto}
                        isSearchable={false}
                        classNamePrefix="select2-selection"
                        defaultValue={opcoesLiquidoBruto[2] || []}
                        onChange={Object => {
                          trocarLiquidoBruto(Object || []);
                        }}
                      />
                    </div>
                  </FormGroup>
                }
                <FormGroup className="ml-2">
                  <Button color="light" onClick={(e) => { atualizarDados(); removerFocusNoBotao(e.currentTarget); }} >
                    <i className="fal fa-hashtag"></i>
                    boralá!
                  </Button>
                </FormGroup>
              </Form>
            </FiltroBrytum>

          </div>
          <Container fluid className="fixWith graficoprincipal mt-3">
            <Row>
              <Col>
                <Card className="mb-0">
                  <CardBody className="cardbodygrafico">
                    {idCliente == "real" && <Grafico index={"chartdiv"} dados={dados} />}
                    <Row>
                      <Col>
                        {idCliente == "benassi" && <GraficoBenassi index={"chartdiv"} dados={dados} opcaoLB={liquidoBruto.value} />}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>


        </div>
      </>
    </React.Fragment>
  );
}
export default GraficoCachoeira;