import React, { useEffect, useState, useRef, useMemo } from 'react';
import PaginaLoader from '../../components/loader/pagina';
import { useDispatch, useSelector } from 'react-redux'
import { requisitarDados, requisitarLimparDados } from "../../store/xpto/actions"
import { gridConfig } from './AppConfig';
import { gridConfigBenassi } from './AppConfigBenassi';
import { FormGroup, Button, Form, Input, Modal } from 'reactstrap';
import { exportarExcel, retornarColunasExcel, replaceAll, Hoje, Mes, Ano, retornarMeses, removerFocusNoBotao, primeiroDiaMes, concatenaLojas, retornarAnos, ArrayToMultiSelect } from "../../helpers/utils";
import { requisitarDadosAmbiente } from "../../store/ambiente/actions"
import '@bryntum/grid/grid.stockholm.css';
import '../../App.scss';
import Header from "../../components/HorizontalLayout/HeaderApp"
import { BryntumGrid } from '@bryntum/grid-react';
import FiltroBrytum from "../../components/filtroBrytum";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import GridModal from '../../components/gridModal/gridModal';
import Vazio from '../../components/vazio';
import FocarPaisagem from '../../components/forcarPaisagem';
import { retornarIdCliente } from '../../cliente/variaveis';
import { consultarDdlCuidadores } from '../../helpers/api';
const animatedComponents = makeAnimated();
const anos = retornarAnos(2);
const meses = retornarMeses();
const idCliente = retornarIdCliente();
const Xpto = (props) => {

    toastr.options = {
        positionClass: "toast-bottom-full-width",
        timeOut: 5000
    }

    //const { addToast } = useToasts();
    const [loading, setLoading] = useState(true);
    const { dados, carregando } = useSelector(state => state.Xpto);
    const [dataInicial, setDataInicial] = useState(primeiroDiaMes(Mes()));
    const [dataFinal, setDataFinal] = useState(Hoje());
    const grid = useRef(null);
    const dispatch = useDispatch();
    const [mostrarFiltros, setMostrarFiltros] = useState(false);
    const [ano, setAno] = useState(Ano(Hoje()));
    const [lojas, setLojas] = useState([]);
    const { lojasDoUsuario } = useSelector(state => state.Ambiente);
    const [habilitarFiltroColunas, setHabilitarFiltroColunas] = useState(true);
    const [mensagemDadosNaoEncontrados, setmensagemDadosNaoEncontrados] = useState(false);
    const [parametrosModal, setParametrosModal] = useState(null);
    const token = useSelector(state => state.Login.user.tokenNOL);
    const [cuidadores, setCuidadores] = useState([]);
    const [cuidador, setCuidador] = useState([]);
    const lojasRef = useRef(null);
    const cuidadorRef = useRef(null);


    lojasRef.current = { lojas, setLojas };
    cuidadorRef.current = { cuidador, setCuidador };

    const atualizarDados = async () => {
        await dispatch(requisitarDados({
            ano_inicial: ano,
            mes_inicial: 1,
            ano_final: ano,
            mes_final: 12,
            resultado: "despesas",
            titulos: "pagar",
            agrupamento: "mensal",
            visualizacao: idCliente == "benassi" ? "agrupador" : "gaveta",
            retorno: "coluna",
            arvore: 5,
            u_unidades_negocio_id: concatenaLojas(lojasRef.current.lojas),
            token,
            exportacao: "mapa_despesas",
            cuidador_id: idCliente == "benassi" ? cuidadorRef?.current?.cuidador?.value || "" : "",
        }));
        setMostrarFiltros(false);
    }
    const atualizarDadosAmbiente = async () => {
        await dispatch(requisitarDadosAmbiente(token, "gerenciais"));
    }

    useEffect(() => {
        atualizarDadosAmbiente();
        atualizarDados();
        consultarDdlCuidadores({ token })
            .then(result => setCuidadores(result))
            .catch();

        return () => dispatch(requisitarLimparDados())
    }, []);

    useEffect(() => {
        if (carregando) return;
        atualizarDadosDemanda();
    }, [carregando]);

    const ocultarModal = () => {
        setParametrosModal({ ...parametrosModal, mostarModal: false })
    }

    const atualizarDadosDemanda = () => {
        setmensagemDadosNaoEncontrados(false);
        setMostrarFiltros(false);
        setLoading(true);

        if (grid.current) {
            document.getElementById(grid.current.instance.id).style.display = "flex";
            grid.current.instance.store.fetchOptions.body = JSON.stringify({
                classeN1: "",
                ano_inicial: ano,
                mes_inicial: 1,
                ano_final: ano,
                mes_final: 12,
                resultado: "despesas",
                titulos: "pagar",
                agrupamento: "mensal",
                visualizacao: idCliente == "benassi" ? "agrupador" : "gaveta",
                retorno: "coluna",
                arvore: 5,
                nivelAtual: 0,
                sob_demanda: 1,
                u_unidades_negocio_id: concatenaLojas(lojasRef.current.lojas),
                exportacao: "mapa_despesas",
                cuidador_id: idCliente == "benassi" ? cuidadorRef?.current?.cuidador?.value || "" : "",
            });
            grid.current.instance.store.removeAll();
            grid.current.instance.store.loadData();
            grid.current.instance.store.load()
                .then((resolve) => {
                    setLoading(false);
                    document.getElementById(grid.current.instance.id).style.display = "flex";



                    if (!resolve.data.length) {
                        document.getElementById(grid.current.instance.id).style.display = "none";
                        setMostrarFiltros(true);
                        setmensagemDadosNaoEncontrados(true);
                    } else {
                        grid.current.instance.on('cellDblClick', (event) => {

                            if (event.record.possui_detalhes) {
                                const coluna = event.cellElement.dataset.column.split("_")[2];
                                let execucaoLojas = false;
                                if (idCliente == "benassi") {
                                    execucaoLojas =(event.record.cr_agrupador_id == "40");
                                }

                                const filtro = {
                                    cf_nivel1_id: event.record.cf_nivel1_id,
                                    cf_nivel2_id: event.record.cf_nivel2_id,
                                    cf_nivel3_id: event.record.cf_nivel3_id,
                                    ano: ano,
                                    meses: !coluna ? ArrayToMultiSelect([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]) : event.cellElement.dataset.column.split("_")[2] + "|;",
                                    visualizacao: (idCliente == "benassi" && !execucaoLojas) ? "dimensao-3" : "dimensao-1",
                                    arvore: (idCliente == "benassi" && !execucaoLojas) ? 4 : 6,
                                    token,
                                    cuidador_id: cuidadorRef?.current?.cuidador?.value ||  "",
                                    origem: "mapa",
                                    u_unidades_negocio_id: concatenaLojas(lojasRef.current.lojas),
                                    execucaoLojas: execucaoLojas
                                };
                                setParametrosModal({ ...event.record.originalData, filtro, mostarModal: true });
                            }
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    const adicionarParametrosModal = (parametros) => {
        let unidades = [];
        if (lojasRef.current.lojas.length) {
            unidades = lojasRef.current.lojas;
        } else {
            lojasDoUsuario.forEach(element => {
                element.options.forEach(element => {
                    unidades.push(element);
                });
            });
        }

        parametros.filtro.ano = ano;
        parametros.filtro.ca_id = replaceAll(replaceAll(concatenaLojas(unidades), "-", ""), ",", "");
        setParametrosModal(parametros);
    }

    const gridInfo = useMemo(() => {
        if (!dados) return
        switch (idCliente) {
            case 'real': return gridConfig(dados, grid, token, adicionarParametrosModal);
            case 'benassi': return gridConfigBenassi(dados, grid, token, adicionarParametrosModal);
            default: return null
        }
    }, [dados])


    const trocarLojas = (valor) => {
        if (valor) {
            setLojas(valor)
        } else {
            setLojas([])
        }
    }

    const trocarAno = (e) => {
        setAno(e.target.value)
    }

    const trocarCuidador = (valor) => {
        if (valor) {
            setCuidador(valor)
        } else {
            setCuidador([])
        }
    }

    return (
        <React.Fragment>
            <FocarPaisagem />
            {parametrosModal?.mostarModal && <GridModal propriedades={parametrosModal} setParametrosModal={setParametrosModal} />}

            <PaginaLoader carregando={loading} />
            <>
                <Header
                    parametros={{
                        exportarExcel: () => exportarExcel({ data: grid.current.gridInstance.store.data, exportacao: dados.exportacao }),
                        botoesExtras: [
                            {
                                funcao: () => {
                                    setMostrarFiltros(!mostrarFiltros)
                                },
                                icone: "bx bx bx-filter-alt"
                            }
                        ]
                    }
                    } position={true} titulo="Mapa de negócios" />
                <FiltroBrytum aberto={mostrarFiltros}>
                    <Form inline>
                        <FormGroup  >
                            <Input defaultValue={ano} type="select" onChange={trocarAno}>
                                {
                                    anos.map((ano, indice) => {
                                        return (
                                            <option key={`ano_${indice}`} value={ano.value}>{ano.label}</option>
                                        )
                                    })
                                }
                            </Input>
                        </FormGroup>
                        <FormGroup className="select2-container ml-2"  >
                            <div style={{ minWidth: 200 }}>
                                <Select
                                    placeholder="Todas as unidades"
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    options={lojasDoUsuario}
                                    isSearchable={false}
                                    isMulti
                                    classNamePrefix="select2-selection"
                                    defaultValue={lojas || []}
                                    onChange={Object => {
                                        trocarLojas(Object || []);
                                    }}
                                />
                            </div>
                        </FormGroup>
                        <FormGroup className="ml-2">
                            <div className="unidadesControler">
                                <Select
                                    placeholder="Cuidadores"
                                    className="basic-single"
                                    options={cuidadores}
                                    isSearchable={false}
                                    classNamePrefix="select2-selection"
                                    isClearable
                                    onChange={Object => {
                                        trocarCuidador(Object || []);
                                    }}
                                />
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <Button color="light" onClick={(e) => { atualizarDadosDemanda(); removerFocusNoBotao(e.currentTarget); }} className="ml-2">
                                <i className="fal fa-hashtag"></i>
                                boralá!
                            </Button>
                        </FormGroup>
                    </Form>

                </FiltroBrytum>
                {mensagemDadosNaoEncontrados && <Vazio />}
                {dados &&
                    <BryntumGrid
                        ref={grid}
                        {...gridInfo}
                        treeFeature={true}
                        columnReordFeature={false}
                        cellEditFeature={false}
                        summaryFeature={true}
                        sortFeature={true}
                        key={carregando}
                        filterBarFeature={{
                            compactMode: habilitarFiltroColunas
                        }}
                        cellMenuFeature={
                            {
                                disabled: true,
                            }
                        }
                        headerMenuFeature={
                            {
                                items: {
                                    hideColumn: false
                                }
                            }
                        }
                    />}

            </>
        </React.Fragment >
    );
}
export default Xpto;