import React, { useEffect, useState, useRef } from 'react';
import { exportarExcel, concatenarDropdown, retornarAnos, retornarMeses, concatenaLojas, Hoje, primeiroDiaMes, Mes, removerFocusNoBotao, toMoney } from "../../helpers/utils";
import PaginaLoader from '../../components/loader/pagina';
import { useDispatch, useSelector } from 'react-redux';
import { requisitarDados, requisitarLimparDados } from "../../store/coposContribuicao/actions";
import ToobarCard from '../toolBarCard';
import { requisitarDadosAmbiente } from "../../store/ambiente/actions";
import Header from "../../components/HorizontalLayout/HeaderApp";
import { Card, CardBody, FormGroup, Button, Form, Container, Input } from 'reactstrap';
import Grafico from "./partes/grafico";
import GraficoBenassi from "./partes/graficoBenassi";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Row, Col } from "reactstrap";
import FiltroCasca from '../../components/filtroGrafico/filtroCasca';
import FocarPaisagem from '../../components/forcarPaisagem';
import { retornarIdCliente } from '../../cliente/variaveis';
import FiltroBrytum from "../../components/filtroBrytum";

const animatedComponents = makeAnimated();
const anos = retornarAnos(6);
const meses = retornarMeses();
const idCliente = retornarIdCliente();
const CoposContribuicao = (props) => {
  const filtros = props?.location?.state?.filtros;
  const carregandoMesAtual = useSelector(state => state.MapaNegociosUnidades.carregando);
  const [mostrarLoader, setMostrarLoader] = useState(true);
  const [tipoGrafico, setTipoGrafico] = useState("margem_contribuicao_percentual");
  const [tipoGraficoValor, setTipoGraficoValor] = useState("margem_contribuicao_valor");
  const [clicouMargem, seClicouMargem] = useState(false);
  const { dados, carregando } = useSelector(state => state.CoposContribuicao);
  const { lojasDoUsuario } = useSelector(state => state.Ambiente);
  const [lojas, setLojas] = useState([]);
  const [mesesSelecionados, setMesesSelecionados] = useState(meses.filter(a => a.value == Mes(Hoje())));
  const [ano, setAno] = useState(anos[0]);
  const [mostrarFiltros, setMostrarFiltros] = useState(false);
  const dispatch = useDispatch();
  const token = useSelector(state => state.Login.user.tokenNOL);

  const mesesSelecionadosRef = useRef(null);
  mesesSelecionadosRef.current = { mesesSelecionados, setMesesSelecionados };

  const lojasRef = useRef(null);
  lojasRef.current = { lojas, setLojas };

  const atualizarDados = async () => {
    switch (idCliente) {
      case "real":
        await dispatch(requisitarDados({
          token,
          u_unidades_id: concatenaLojas(lojasRef.current.lojas),
          ano: ano.value,
          meses: mesesSelecionadosRef.current.mesesSelecionados.length ? concatenarDropdown(mesesSelecionadosRef.current.mesesSelecionados) : concatenarDropdown(meses),
          agrupamento: "total",
          visualizacao: "unidade",
          retornaMC3: 1,
          exportacao: idCliente == "benassi" ? "zoom-de-desempenho" : "batida_tambor"
        }
        ));
        break;

      case "benassi":
        await dispatch(requisitarDados({
          token,
          u_unidades_id: filtros.filial,
          u_negocios_id: filtros.negocio,
          r_redes_id: filtros.rede,
          t_terceiros_id: filtros.loja,
          tipo_cmv: filtros.tipoCusto == "01" ? "medio" : "gerencial",
          ano: filtros.tipoPeriodo == "1" ? filtros.ano : "",
          meses: filtros.tipoPeriodo == "1" ? filtros.mes : "",
          agrupamento: "total",
          visualizacao: filtros.tipoDesempenho,
          retornaMC3: 1,
          exportacao: idCliente == "benassi" ? "zoom-de-desempenho" : "batida_tambor",
          base_calculo_percentual: idCliente == "benassi" ? "fixa" : null
        }
        ));
        break;
    }

  }

  const atualizarDadosAmbiente = async () => {
    await dispatch(requisitarDadosAmbiente(token));
  }
  useEffect(() => {
    atualizarDadosAmbiente();
    atualizarDados();
    return () => {
      dispatch(requisitarLimparDados())
    }
  }, []);



  const trocarLojas = (valor) => {
    if (valor) {
      setLojas(valor)
    } else {
      setLojas("")
    }
  }

  const atualizarLojas = () => {
    atualizarDados();
  }
  const trocarAno = (valor) => {
    if (valor) {
      setAno(valor)
    } else {
      setAno([])
    }
  }
  const trocarMes = (valor) => {
    if (valor) {
      setMesesSelecionados(valor)
    } else {
      setMesesSelecionados([])
    }
  }



  return (
    <React.Fragment>
      <FocarPaisagem />
      <PaginaLoader carregando={(carregando || carregandoMesAtual) && mostrarLoader} />
      <>
        {idCliente == "benassi" && <Header
          url={"/filtro-unidades/zoom-de-desempenho"}
          parametros={
            {
              exportarExcel: () => exportarExcel(dados)
            }
          } />}
        {idCliente == "real" && <Header
          parametros={
            {
              botoesExtras: [
                {
                  funcao: () => {
                    setMostrarFiltros(!mostrarFiltros)
                  },
                  icone: "bx bx bx-filter-alt"
                }
              ],
              exportarExcel: () => exportarExcel(dados),
            }
          } />}
        {dados?.data?.length &&
          <div id="layout-wrapper">
            <div className="calcularaltura">
              {idCliente == "real" &&
                <FiltroBrytum aberto={mostrarFiltros}>
                  <Form inline>
                    <FormGroup className="select2-container"  >
                      <div className="unidadesControler">
                        <Select
                          placeholder="Todas as unidades"
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          options={lojasDoUsuario}
                          isSearchable={false}
                          isMulti
                          classNamePrefix="select2-selection"
                          defaultValue={lojas || []}
                          onChange={Object => {
                            trocarLojas(Object || []);
                          }}
                        />
                      </div>
                    </FormGroup>
                    <FormGroup className="ml-2">
                      <div className="unidadesControler anoControler">
                        <Select
                          placeholder="Todas as unidades"
                          className="basic-single"
                          options={anos}
                          isSearchable={false}
                          classNamePrefix="select2-selection"
                          defaultValue={anos[0] || []}
                          onChange={Object => {
                            trocarAno(Object || []);
                          }}
                        />
                      </div>


                    </FormGroup>
                    <FormGroup className="select2-container ml-2"  >
                      <div className="unidadesControler">
                        <Select
                          defaultValue={meses.filter(a => a.value == Mes(Hoje()))}
                          isMulti
                          name="meses"
                          options={meses}
                          placeholder="Todos os meses"
                          closeMenuOnSelect={false}
                          classNamePrefix="select2-selection"
                          isSearchable={false}
                          onChange={Object => {
                            trocarMes(Object || []);
                          }}
                        />
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <Button color="light" onClick={(e) => { setMostrarFiltros(!mostrarFiltros); atualizarLojas(); removerFocusNoBotao(e.currentTarget); setMostrarLoader(true); }} className="ml-2">
                        <i className="fal fa-hashtag"></i>
                        boralá!
                      </Button>
                    </FormGroup>
                    <ToobarCard
                      botoes={
                        [
                          {
                            nome: dados?.header?.margem_contribuicao_valor,
                            color: "dark",
                            ativo: tipoGrafico === "margem_contribuicao_percentual",
                            outline: true,
                            funcao: () => {
                              setTipoGrafico("margem_contribuicao_percentual");
                              setTipoGraficoValor("margem_contribuicao_valor");
                              seClicouMargem(true);
                            }
                          },
                          {
                            nome: dados?.header?.margem_ii,
                            color: "dark",
                            ativo: tipoGrafico === "margem_ii_percentual",
                            outline: true,
                            funcao: () => {
                              setTipoGrafico("margem_ii_percentual");
                              setTipoGraficoValor("margem_ii");
                              seClicouMargem(true);

                            }
                          },
                          {
                            nome: dados?.header?.margem_iii,
                            color: "dark",
                            ativo: tipoGrafico === "margem_iii_percentual",
                            outline: true,
                            funcao: () => {
                              setTipoGrafico("margem_iii_percentual");
                              setTipoGraficoValor("margem_iii");
                              seClicouMargem(true);

                            }
                          }
                        ]
                      }
                      tipoGrafico={tipoGrafico}
                      setTipoGrafico={setTipoGrafico} >


                    </ToobarCard>
                  </Form>
                </FiltroBrytum>
              }
            </div>
            {idCliente == "real" &&
              <Container fluid style={{ maxWidth: "100%" }}>
                <Row className="mt-4">
                  {
                    dados.data.map((dado, i) => {
                      return (
                        <Col sm={6} md={4} xl={3} lg={3} key={`headerMaps_${i}`}>
                          <Card className="tacasDaContribuicao">
                            <CardBody>
                              <h4 className="card-title">
                                <i className={`${dado.iconCls} mr-2`}></i>
                                {dado.u_nome}
                              </h4>
                              <Row>
                                <Col md={6} lg={6}>
                                  <Grafico dado={dado} tipoGrafico={tipoGrafico} clicouMargem={clicouMargem} />
                                </Col>
                                <Col md={6} className="align-self-center">
                                  <div className="mb-3">
                                    <p className="mb-1">
                                      <i className="mdi mdi-circle align-middle font-size-10 me-2 text-success" />{" "}
                                      {dados?.header?.faturamento || " "}
                                    </p>
                                    <h5>
                                      {`R$ ${toMoney(dado.faturamento, 0)}`}
                                    </h5>
                                  </div>
                                  <div className="mb-3">
                                    <p className="mb-1">
                                      <i className="mdi mdi-circle align-middle font-size-10 me-2 text-danger" />{" "}
                                      {dados?.header?.cmv_valor || " "}
                                    </p>
                                    <h5>
                                      {`${toMoney(dado.cmv_percentual, 1)}%`}
                                      <span className="text-muted ml-2">{`${toMoney(dado.cmv_valor, 0)}`}</span>
                                    </h5>
                                  </div>
                                  <div>
                                    <p className="mb-1">
                                      <i className="mdi mdi-circle align-middle font-size-10 me-2 text-dark" />{" "}
                                      {dados?.header[`${tipoGraficoValor}`] || " "}
                                    </p>
                                    <h5>
                                      {`${toMoney(dado[tipoGrafico], 1)}%`}
                                      <span className="text-muted ml-2">{`${toMoney(dado[tipoGraficoValor], 0)}`}</span>
                                    </h5>
                                  </div>

                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      )
                    })}

                  <Col sm={6} md={4} xl={3} lg={3}>
                    <Card className="tacasDaContribuicao border border-primary card border-primary">
                      <CardBody>
                        <h4 className="card-title">
                          <i className="fal fa-hand-holding-usd mr-2"></i>Nação Real
                        </h4>
                        <Row>
                          <Col md={6} lg={6}>
                            <Grafico dado={dados.footer} tipoGrafico={tipoGrafico} clicouMargem={clicouMargem} />
                          </Col>
                          <Col md={6} className="align-self-center">
                            <div className="mb-3">
                              <p className="mb-1">
                                <i className="mdi mdi-circle align-middle font-size-10 me-2 text-success" />{" "}
                                {dados?.header?.faturamento || " "}
                              </p>
                              <h5>
                                {`R$ ${toMoney(dados.footer.faturamento, 0)}`}
                              </h5>
                            </div>
                            <div className="mb-3">
                              <p className="mb-1">
                                <i className="mdi mdi-circle align-middle font-size-10 me-2 text-danger" />{" "}
                                {dados?.header?.cmv_valor || " "}
                              </p>
                              <h5>
                                {`${toMoney(dados.footer.cmv_percentual, 1)}%`}
                                <span className="text-muted ml-2">{`${toMoney(dados.footer.cmv_valor, 0)}`}</span>
                              </h5>
                            </div>
                            <div>
                              <p className="mb-1">
                                <i className="mdi mdi-circle align-middle font-size-10 me-2 text-dark" />{" "}
                                {dados?.header[`${tipoGraficoValor}`] || " "}
                              </p>
                              <h5>
                                {`${toMoney(dados.footer[tipoGrafico], 1)}%`}
                                <span className="text-muted ml-2">{`${toMoney(dados.footer[tipoGraficoValor], 0)}`}</span>
                              </h5>
                            </div>

                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

              </Container>
            }
            {idCliente == "benassi" &&
              <Container fluid style={{ maxWidth: "100%" }}>
                <Row className="mt-4">
                  {
                    dados.data.map((dado, i) => {
                      return (
                        // <Col sm={6} md={6} xl={6} lg={6} key={`headerMaps_${i}`}>
                        <Col sm={4} key={`headerMaps_${i}`}>
                          <Card className="tacasDaContribuicao">
                            <CardBody>
                              <h4 className="card-title">
                                <i className={`${dado.iconCls} mr-2`}></i>
                                {dado.u_nome}
                              </h4>
                              <Row>
                                <Col md={12} lg={12}>
                                  <GraficoBenassi dados={{ id: "grafico_benassi_" + i, data: dado, header: dados.header, footer: dados.footer }} header={dados.header} tipoGrafico={tipoGrafico} clicouMargem={clicouMargem} />
                                </Col>
                                {/* <Col md={3} className="align-self-center">
                                  <div className="mb-3">
                                    <p className="mb-1">
                                      <i className="mdi mdi-circle align-middle font-size-10 me-2 text-danger" />{" "}
                                      Folha
                                    </p>
                                    <h5>
                                      {`R$ ${toMoney(840700)}`}

                                    </h5>
                                  </div>
                                  <div className="mb-3">
                                    <p className="mb-1">
                                      <i className="mdi mdi-circle align-middle font-size-10 me-2 text-danger" />{" "}
                                      Frete
                                    </p>
                                    <h5>
                                      {`R$ ${toMoney(8227561)}`}
                                    </h5>
                                  </div>
                                  <div>
                                    <p className="mb-1">
                                      <i className="mdi mdi-circle align-middle font-size-10 me-2 text-danger" />{" "}
                                      Demais
                                    </p>
                                    <h5>
                                      {`R$ ${toMoney(171000)}`}
                                    </h5>
                                  </div>

                                </Col> */}
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      )
                    })}

                  {/* <Col sm={6} md={4} xl={3} lg={3}> */}
                  <Col sm={4}>
                    <Card className="tacasDaContribuicao border border-primary card border-primary">
                      <CardBody>
                        <h4 className="card-title">
                          <i className="fal fa-hand-holding-usd mr-2"></i>Totalizador
                        </h4>
                        <Row>
                          <Col md={12} lg={12}>
                            <GraficoBenassi dados={{ id: "grafico_benassi_total", data: dados.footer, header: dados.header, footer: dados.footer }} header={dados.header} tipoGrafico={tipoGrafico} clicouMargem={clicouMargem} />
                          </Col>
                          {/* <Col md={3} className="align-self-center">
                            <div className="mb-3">
                              <p className="mb-1">
                                <i className="mdi mdi-circle align-middle font-size-10 me-2 text-danger" />{" "}
                                Folha
                              </p>
                              <h5>
                                {`R$ ${toMoney(840700)}`}

                              </h5>
                            </div>
                            <div className="mb-3">
                              <p className="mb-1">
                                <i className="mdi mdi-circle align-middle font-size-10 me-2 text-danger" />{" "}
                                Frete
                              </p>
                              <h5>
                                {`R$ ${toMoney(8227561)}`}
                              </h5>
                            </div>
                            <div>
                              <p className="mb-1">
                                <i className="mdi mdi-circle align-middle font-size-10 me-2 text-danger" />{" "}
                                Demais
                              </p>
                              <h5>
                                {`R$ ${toMoney(171000)}`}
                              </h5>
                            </div>

                          </Col> */}
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

              </Container>
            }
          </div>
        }
      </>
    </React.Fragment>
  );
}
export default CoposContribuicao;